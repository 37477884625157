import React from "react";
import { useLocation } from "@reach/router";
import { Link, graphql } from "gatsby";

import SidebarPartnering from "../components/SidebarPartnering";
import styled from "styled-components";
import HeroSectionComponent from "../components/HeroSectionComponent";
import ContentWithSideBar from "../components/ContentWithSideBar";
import ProductCard from "../components/ProductCard";
import Seo from "../components/SEO";

const ProductList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    width: 100%;
    max-width: 80rem;
  }
`;

const SideBarWrapper = styled.aside`
  width: var(--width-percentage);
  margin-top: 5rem;

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
  }

  @media (min-width: 1024px) {
    margin-left: 2.4rem;
    margin-top: 0px;
    flex-direction: column;
    width: 24rem;
  }
`;

const NoProductWrapper = styled.div`
  flex: 1;
  padding: 2rem;
  background: var(--grey-dark);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 1rem;
  }
`;

export default function OurBrands({ data, pageContext }) {
  const products = data.products.nodes;

  return (
    <>
      <Seo
        title={data.brand.brand_name}
        description={pageContext.description}
        image={data.brand?.brand_image?.asset?.url}
        location={useLocation()}
      />
      <HeroSectionComponent
        titleUpperCase={true}
        title={pageContext.name}
        text={pageContext.description}
        link='/our-brands'
        linkText='back to our brands'
        heroBackgroundImage={data.brand.brand_bg_image.asset.gatsbyImageData}
        heroImage={false}
        isProductPage={true}
      />
      <ContentWithSideBar>
        {products.length === 0 ? (
          <NoProductWrapper>
            <h4>Coming soon</h4>
            <Link to='/our-brands' className='black-button'>
              view all of our brands
            </Link>
          </NoProductWrapper>
        ) : (
          <ProductList>
            {products.map((product) => (
              <li key={product.id}>
                <ProductCard
                  itemImage={product.product_image.asset.gatsbyImageData}
                  itemLink={product.product_slug.current}
                  itemBrand={product.product_brand.brand_name}
                  itemTitle={product.product_name}
                  itemChinese={product.product_chinese_name}
                  itemCode={product.product_code}
                  itemInfo={product.product_info}
                  borderColor='var(--grey-light)'
                  smallVersion={false}
                />
              </li>
            ))}
          </ProductList>
        )}
        <SideBarWrapper>
          <SidebarPartnering />
        </SideBarWrapper>
      </ContentWithSideBar>
    </>
  );
}

export const query = graphql`
  query ($name: String!) {
    products: allSanityProduct(
      filter: { product_brand: { brand_name: { eq: $name } } }
    ) {
      nodes {
        id
        product_name
        product_chinese_name
        product_code
        product_info
        product_description
        product_slug {
          current
        }
        product_image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }

        product_brand {
          brand_name
          brand_image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          brand_bg_image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    brand: sanityBrand(brand_name: { eq: $name }) {
      brand_name
      brand_image {
        asset {
          url
        }
      }
      brand_bg_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
