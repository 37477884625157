import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";

import styled from "styled-components";

const SideBarSectionWrapper = styled.section`
  text-align: center;
  margin: 0px 0px 5rem;

  p {
    margin-top: 0px;
  }

  @media (min-width: 768px) {
    text-align: left;
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

const SideBarWrapperTitle = styled.h3`
  font-size: 2.8rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    text-align: left;
    display: inline-block;
    align-self: flex-start;
  }

  &::after {
    content: "";
    width: 5rem;
    height: 0.4rem;
    background-color: var(--red-dark);
    position: absolute;
    margin: auto;
    bottom: -1rem;
    left: 0px;
    right: 0px;

    @media (min-width: 768px) {
      margin-left: 0px;
    }
  }

  span {
    color: var(--red-dark);
    font-size: 2.2rem;
    font-family: var(--font-quattrocento-regular);
    text-transform: capitalize;
    width: 100%;
    display: block;
    line-height: 1;
    margin: 0px;
  }
`;

export default function SidebarPartnering() {
  const { partnerText } = useStaticQuery(graphql`
    query {
      partnerText: sanitySidebarPartner {
        sidebar_partner_text
      }
    }
  `);
  return (
    <SideBarSectionWrapper>
      <SideBarWrapperTitle>
        <span>partnering</span> with shuey shing
      </SideBarWrapperTitle>
      <p>{partnerText.sidebar_partner_text}</p>
      <Link to='/contact-us' className='red-button'>
        enquire now
      </Link>
    </SideBarSectionWrapper>
  );
}
